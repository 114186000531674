import { api } from '../../../Config';
import RefreshToken from '../../../RefreshToken';
import * as msg from '../../reusables/toast/Toast';
import Style from './Style.module.css';
import { useEffect, useState } from 'react';
import { Container, Card, CardContent, Grid, IconButton, Tooltip, TextField, Fab } from '@material-ui/core';
import { Done, Close, Check} from '@material-ui/icons';
import { useLocation } from "react-router-dom"
import Authenticated from '../../reusables/authenticated/Authenticated';
import Loading from '../../reusables/loading/Loading';
import NoDataToDisplay from '../../reusables/noDataToDisplay/NoDataToDisplay';
import PageTitle from '../../reusables/pageTitle/PageTitle';

import { FormControl, InputLabel, Select, MenuItem, Button, ButtonGroup } from '@material-ui/core';

/////
import {Edit, Add} from '@material-ui/icons';
import Modal from '../../reusables/modal/Modal';
import Register from './register/Register';

function ObservationsFilesReceived() {
    const campaign = useLocation().campaign;
    const campaign_name = useLocation().campaign_name;
    const [element, setElement] = useState('');
    const [elements, setElements] = useState([]);
    const [processing, setProcessing] = useState(true);
    const [registering, setRegistering] = useState(false);
    const [parameters, setParameters] = useState([]);
    const [result_types, setResult_types] = useState([]);

    const [actions, setActions] = useState([]);
    const [action, setAction] = useState('');
    const [deleting, setDeleting] = useState(false);

    const [registeringac, setRegisteringac] = useState(false);

    /*const [listform, setListform] = useState([{
        observation: '',
        acceptance_status: '',
        observation_analyst: '',
        result_type: ''
      }]);*/


    useEffect(() => {
        getElements();
        getParameters();
        getResult_types();

        getAcciones();

    }, []);

    const handleChange = (index, event) => {
        const { name, value } = event.target;
        setElements(prevListform => {
            const newListform = [...prevListform];
            newListform[index][name] = value;
            newListform[index][name] = value;

            if (name === "acceptance_status"){
                newListform[index]['result_type'] = parameters.find(p => p.id === value)?.result_type;
            }
            //newListform[index]['result_type'] = 'C';
            return newListform;
        });
        
    };

    function getParameters(){
        setProcessing(true)
        fetch(api.urlAcceptanceParameters, {
            headers: {'Authorization': `Bearer ${localStorage.getItem("accessToken")}`}
        })
        .then((data) => {
            if (data.status === 200){
                data.json().then((data) => {
                    setParameters(data)
                    setProcessing(false)
                })
            } else if (data.status === 401) {
                RefreshToken()
                getParameters()
            } else {
                msg.error('Se ha producido un error')
            }
        })
        //.catch(console.log)
    }

    function getResult_types(){
        fetch(api.urlAcceptanceParameters, {
            method: 'options',
            headers: {'Authorization': `Bearer ${localStorage.getItem("accessToken")}`}
        })
        .then((data) => {
            if (data.status === 200){
                data.json().then((data) => {
                    setResult_types(data.actions.POST.result_type.choices);
                })
            } else if (data.status === 401) {
                RefreshToken()
                getResult_types()
            } else {
                msg.error('Se ha producido un error')
            }
        })
        //.catch(console.log)
    }

    function getElements(){
        setProcessing(true)
        fetch(api.urlFileCampaign+'?campaign='+campaign, {
            headers: {'Authorization': `Bearer ${localStorage.getItem("accessToken")}`}
        })
        .then((data) => {
            if (data.status === 200){
                data.json().then((data) => {
                    setElements(data)
                        //setListform(data)
                    setProcessing(false)
                })
            } else if (data.status === 401) {
                RefreshToken()
                getElements()
            } else {
                msg.error('Se ha producido un error')
            }
        })
        //.catch(console.log)
    }

    function saveAll(obser){
        //console.log("obser:", obser)
        setProcessing(true)
        setRegistering(true)
        fetch(api.urlFileCampaignObservations, {
            method: 'put',
            headers: {'Authorization': `Bearer ${localStorage.getItem("accessToken")}`, 'Content-Type': 'application/json'},
            body: JSON.stringify({
                'observations': obser
            })
        })
        .then((data) => {
            if (data.status === 200){
                setRegistering(false)
                setProcessing(false)
                msg.success('Registro exitoso')
            } else if (data.status === 401) {
                RefreshToken()
                saveAll(obser)
            } else {
                msg.error('Se ha producido un error')
            }

            setProcessing(false)
        })
    }

    function register(e){
        if (e.observation_analyst) {
            setElement(e)
            setRegistering(true)
            fetch(api.urlFileCampaign+e.uuid+'/', {
                method: 'put',
                headers: {'Authorization': `Bearer ${localStorage.getItem("accessToken")}`,'Content-Type': 'application/json'},
                body: JSON.stringify({
                    /*'observation': document.getElementsByName(e.id)[0].value,
                    'acceptance_status': document.getElementsByName("acceptance_status"+e.id)[0].value,
                    'observation_analyst': document.getElementsByName("observation_analyst"+e.id)[0].value,
                    'result_type': document.getElementsByName("result_type"+e.id)[0].value*/
                    'observation': e.observation,
                    'acceptance_status': e.acceptance_status,
                    'observation_analyst': e.observation_analyst,
                    'result_type': e.result_type
                })
            })
            .then((data) => {
                if (data.status === 200){
                    setRegistering(false)
                    msg.success('Registro exitoso')
                } else if (data.status === 401) {
                    RefreshToken()
                    register(e)
                } else {
                    msg.error('Se ha producido un error')
                }
            })
            //.catch(console.log)
        } else {
            msg.warning('Debes agregar las observaciones')
        }
    }

    /* inicio para acciones inmediantas */
    function getAcciones(){
        setProcessing(true)
        fetch(api.urlImmediateActionsCampaign+'?campaign='+campaign, {
            headers: {'Authorization': `Bearer ${localStorage.getItem("accessToken")}`}
        })
        .then((data) => {
            if (data.status === 200){
                data.json().then((data) => {
                    setActions(data)
                    setProcessing(false)
                })
            } else if (data.status === 401) {
                RefreshToken()
                getAcciones()
            } else {
                msg.error('Se ha producido un error')
            }
        })
        //.catch(console.log)
    }

    function confirmDel(e){
        setDeleting(true)
        setAction(e)
    }

    function del(){
        setProcessing(true)
        fetch(api.urlImmediateActionsCampaign+action.id+'/', {
            method: 'delete',
            headers: {'Authorization': `Bearer ${localStorage.getItem("accessToken")}`}
        })
        .then((data) => {
            if (data.status === 204){
                setDeleting(false)
                setProcessing(false)
                getAcciones()
                msg.success('Eliminación exitosa')
            } else if (data.status === 401) {
                RefreshToken()
                del()
            } else {
                msg.error('Se ha producido un error')
            }
        })
        //.catch(console.log)
    }

    function hideModal(){
        setRegisteringac(false)
        setDeleting(false)
    }

    function update(e){
        setRegisteringac(true)
        setAction(e)
    }

    function register_accion(){
        setRegisteringac(true)
        setAction('')
    }

    return (
        <Authenticated>
            <br/><br/><br/>
            <Container maxWidth="lg">
                <PageTitle primary="Archivos recibidos" secondary="Agrega las observaciones necesarias para cada archivo recibido" backUrl="campaign-management"/>
                <PageTitle primary={campaign_name}/>
                <br/>
                {processing ?
                    <Loading variant="list" column={1} height={50}/>
                :
                    elements.length > 0 ?
                        elements.map((item, index) => (
                            <div key={index}>
                                <Card className={registering && element.id === item.id ? Style.processing +' '+ Style.item : Style.item}>
                                    <CardContent>
                                        <Grid container spacing={3}>
                                            <Grid item xs={4}>
                                                <div className={Style.title}>{item.status==="No enviado"?<Close/>:<Check/>} {item.type_filecampaign==="A"?item.file?.name:item.observation}</div>
                                                <div className={Style.detail}>{item.file?.description}</div>
                                            </Grid>

                                            <Grid item xs={4}>
                                                <FormControl variant="outlined" size="small">
                                                    <InputLabel>Parametro</InputLabel>
                                                    <Select value={item.acceptance_status} onChange={(event) => handleChange(index, event)/*handleParChange(event, item)*/} name={"acceptance_status"/*+item.id.toString()*/} label="Parametro">
                                                        <MenuItem value="">
                                                            <em>ninguno</em>
                                                        </MenuItem>

                                                        {parameters.map((element, index) => (
                                                            <MenuItem key={index} value={element.id}>{element.name}</MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={2}>
                                                <FormControl variant="outlined" size="small">
                                                    <Select disabled value={item.result_type} onChange={(event) => handleChange(index, event)} name={"result_type"/*+item.id.toString()*/} label="Tipo resultado">
                                                        {result_types.map((result_type, index) => (
                                                            <MenuItem key={index} value={result_type.value}>{result_type.display_name}</MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={2}>
                                            
                                                {item.type_filecampaign==="A"?
                                                    <>
                                                        <div className={Style.detail}>Archivo {item.file?.extension}</div>
                                                        <div className={Style.detail}>Riesgo {item.file?.risk}</div>
                                                        <div className={Style.detail}>{item.file?.file_type}</div></>:
                                                <></>}
                                            </Grid>
                                            {item.type_filecampaign==="A"?
                                            <Grid item xs={4}>
                                                <TextField value={item.observation} onChange={(event) => handleChange(index, event)} label="Observaciones del cliente" name={"observation" /* item.id.toString() */} variant="outlined" size="small" inputProps={{maxLength:100}} autoFocus={index === 0 ? true : false}/>
                                            </Grid>:<></>}
                                            <Grid item xs={item.type_filecampaign==="A"?5:9}>
                                                <TextField value={item.observation_analyst} onChange={(event) => handleChange(index, event)} label="Observaciones del analista" name={"observation_analyst"/*+item.id.toString()*/} variant="outlined" size="small" inputProps={{maxLength:400}} autoFocus={index === 0 ? true : false}/>
                                            </Grid>
                                            
                                            <Grid item xs={1} className={Style.options}>
                                                <Tooltip title="Registrar observaciones" placement="left" arrow>
                                                    <IconButton onClick={() => register(item)} className={Style.option}>
                                                        <Done/>
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>

                                            <Grid item xs={10}>
                                                {item.error_log}
                                            </Grid>
                                            
                                        </Grid>
                                    </CardContent>
                                </Card>
                                <br/>
                            </div>
                        ))
                        
                    : <NoDataToDisplay/>
                    
                }
                <ButtonGroup size="small" aria-label="small outlined button group">
                        <Button onClick={() => saveAll(elements)}>Guardar todo</Button>
                    </ButtonGroup>

                    
            </Container>
            <br/><br/>

            {/*CRUD de ACCIONES INMEDIATAS*/}
            <Container maxWidth="lg">
            <PageTitle primary="Acciones inmediatas" secondary="Las acciones que el analisista sugiere realizar"/>
            <Fab onClick={register_accion} color="secondary">
                <Add fontSize="large" />
            </Fab>
            <br/>

            {processing && !registeringac && !deleting ?
                <Loading variant="list" column={1} height={50}/>
            :
                actions.map((item, index) => (
                    <div key={index}>
                        <Card className={Style.item}>
                            <CardContent>
                                <Grid container spacing={2}>
                                    <Grid item xs={10}>
                                        <div className={Style.title}>{item.observation}</div>
                                    </Grid>
                                    <Grid item xs={2} className={Style.options}>
                                        <Tooltip title="Editar" placement="left" arrow>
                                            <IconButton onClick={() => update(item)}>
                                                <Edit/>
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Eliminar" placement="left" arrow>
                                            <IconButton onClick={() => confirmDel(item)}>
                                                <Close/>
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                        <br/>
                    </div>
                ))
            }
        </Container>
        <br/><br/>
        
        {registeringac ? <Register element={action} campaign={campaign} getElements={getAcciones} hideModal={hideModal}/> : <></>}
        {deleting ?
            <Modal
                title="Confirmaci&oacute;n"
                content="¿ Deseas continuar con la eliminación ?"
                secondaryActionName="Cancelar"
                primaryActionName="Continuar"
                secondaryAction={hideModal}
                primaryAction={del}
                primaryActionDisabled={false}
                processing={processing}/>
        : <></>}

        </Authenticated>
    );
}

export default ObservationsFilesReceived;