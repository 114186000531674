//const urlBase = ''
//const urlBase = 'http://localhost:8000/'
//const urlBase = 'http://64.23.133.174:8000/'
const urlBase = 'https://api.infoscsp.com/'

/*export const api = {
    urlLogin: urlBase+'../api-emailsecuritytesting/auth/login/',
    urlDuoVerification: urlBase+'../api-emailsecuritytesting/auth/duo-authentication/',
    urlRefreshToken: urlBase+'../api-emailsecuritytesting/auth/refresh-token/',
    urlFiles: urlBase+'../api-emailsecuritytesting/files/',
    urlClients: urlBase+'../api-emailsecuritytesting/clients/',
    urlCampaigns: urlBase+'../api-emailsecuritytesting/campaign/',
    urlFileCampaign: urlBase+'../../api-emailsecuritytesting/filecampaign/',
    urlPhishingCampaign: urlBase+'../api-emailsecuritytesting/phishing-campaign/',
    urlMailCheckDownload: urlBase+'../api-emailsecuritytesting/mail-check/download/',
    urlPhishingDownload: urlBase+'../api-emailsecuritytesting/phishing/download-campaign/',
    urlPhishingCampaignExecution: urlBase+'../api-emailsecuritytesting/phishing-campaign-execution/',
    urlHtmlRenderer: urlBase+'../../../api-emailsecuritytesting/phishing/render-html/',
    urlCopyCampaign: urlBase+'../api-emailsecuritytesting/phishing/copy-campaign/',
};*/

export const api = {
    urlLogin: urlBase+'../auth/login/',
    urlDuoVerification: urlBase+'../auth/duo-authentication/',
    urlRefreshToken: urlBase+'../auth/refresh-token/',
    urlFiles: urlBase+'../files/',
    urlClients: urlBase+'../clients/',
    urlCampaigns: urlBase+'../campaign/',
    urlFileCampaign: urlBase+'../../filecampaign/',
    urlPhishingCampaign: urlBase+'../phishing-campaign/',
    urlMailCheckDownload: urlBase+'../mail-check/download/',
    urlPhishingDownload: urlBase+'../phishing/download-campaign/',
    urlPhishingCampaignExecution: urlBase+'../phishing-campaign-execution/',
    urlHtmlRenderer: urlBase+'../../../phishing/render-html/',
    urlCopyCampaign: urlBase+'../phishing/copy-campaign/',

    urlIndustry: urlBase+'../industries/',
    urlCsrfToken: urlBase+'../auth/csrf_cookie/',
    urlGetPermissions: urlBase+'../auth/get_permissions/',
    
    urlActivateCampaign: urlBase+'../mail-check/activate_campaign/',
    urlResendEmailActivate: urlBase+'../mail-check/resend_email_activate/',
    urlFileCampaignObservations: urlBase+'../mail-check/filecampaign_observations/',

    urlRepHistoricoDetallado: urlBase+'../mail-check/reporte_historico_detallado/',
    urlRepHistoricoResumen: urlBase+'../mail-check/reporte_historico_resumido/',
    urlRepComparativo3: urlBase+'../mail-check/reporte_comparativo_3/',

    urlMailCheckDownloadExcel: urlBase+'../mail-check/download_excel/',
    urlMailCheckDownloadGrafExcelPdf: urlBase+'../mail-check/download_graf_excel_pdf/',

    urlAcceptanceParameters: urlBase+'../acceptance_parameters/',
    urlImmediateActionsCampaign: urlBase+'../../immediateactionscampaign/',

    /** conexion usuarios ldap**/
    urlUsers: urlBase+'../get_list_users/',
    urlPermisoUser: urlBase+'../get_permisos_user/',
    urlMntUsers: urlBase+'../mnt_users/', // agregar (post), modificar (put)

    urlEstadoUser: urlBase+'../estado_user/',
    urlExisteUser: urlBase+'../existe_user/',
    urlActivate: urlBase+'../activate/',

    urlResendActivation: urlBase+'../resend_activation/',
    
};

export const homePage = '/';

export const applicationContext = '';