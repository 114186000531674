import { api } from '../../Config';
import * as msg from '../reusables/toast/Toast';
import Style from './Style.module.css';
import { useState, useEffect } from 'react';
import Modal from '../reusables/modal/Modal';
import { TextField, Zoom, Button } from '@material-ui/core';
import { useParams } from 'react-router-dom';

import NotMatchPage from '../notMatchPage/NotMatchPage';

import CSRFToken from '../reusables/CSRFToken/CSRFToken';

function ActivateUserAccount() {
    const [uuid] = useState(useParams().uuid);
    //const [observations, setObservations] = useState('');

    const [saving, setSaving] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [completed, setCompleted] = useState(false);

    const [existe, setExiste] = useState(false);
    const [form, setForm] = useState({
        password: '',
        repassword: ''
    });

    useEffect(() => existeUser(), []);

    function existeUser(){
        fetch(api.urlExisteUser+uuid+'/')
        .then((data) => {
            if (data.status !== 200){
                setExiste(false);
                msg.error('Se ha producido un error')
            } else {setExiste(true);}

        })
        //.catch(console.log)
    }

    /*function confirm(){
        setSaving(true)
    }
    function cancel(){
        setSaving(false)
    }*/

    const handleChange = event => {
        setForm({ ...form, [event.target.name]: event.target.value });
    };

    function register(){
        if (form.password !== form.repassword) {
            msg.error('Las contraseñas no son iguales')
            return
        }
        setProcessing(true)
        setSaving(false)
        const csrftoken_pag = document.querySelector('[name=csrfmiddlewaretoken]').value;
        fetch(api.urlActivate, {
            method: 'put',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-CSRFToken': csrftoken_pag,
            },
            credentials: 'include',
            body: JSON.stringify({
                'uuid': uuid,
                'password': form.password
            })
        })
        .then((data) => {
            if (data.status === 200){
                setProcessing(false)
                setCompleted(true)
                msg.success('activación exitosa')
                window.location.href = '../login';
            } else {
                data.json().then((data) => {
                    if (data.detail){
                        msg.error(data.detail)
                    }
                })
                setProcessing(false)
                msg.error('Se ha producido un error')
            }
        })
        //.catch(console.log)
    }


    return (
        <>
        {!existe ? <NotMatchPage/> :
        <Zoom in={true}>
            <div className={Style.container}>
                <div>
                    <img src="../logo.png" alt="Sistemas Aplicativos, S.A." />
                    <br/><br/>
                    <div className={Style.paper}>
                            <>
                                <CSRFToken />
                                <span className={Style.titulo}>Activar cuenta</span>
                                
                                <br/><br/>
                                <TextField label="Ingrese contraseña" type="password" name="password" onKeyUp={handleChange} autoFocus={true} inputProps={{maxLength:100}}/>
                                <br/><br/>
                                <TextField label="Confirme contraseña" type="password" name="repassword" onKeyUp={handleChange} inputProps={{maxLength:100}}/>
                                <br/><br/><br/>
                                <Button onClick={register} disabled={form.password === '' || form.repassword === '' ? Boolean(true) : Boolean(false)} variant="outlined" color="secondary" size="large">Activar</Button>
                                <br/><br/>
                            </>
    
                    </div>
                    <br/>
                    <div className={Style.copyright}>
                        All rights reserved. ® Copyright {new Date().getFullYear()}.
                        <br/>
                        Sistemas Aplicativos, S.A.
                    </div>
                </div>
            </div>
        </Zoom>}

        </>
    );
}

export default ActivateUserAccount;